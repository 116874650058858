import CarouselCV, { Item } from "@components/Carousel";
import Title from "@components/Titles";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Box, Container, Grid, Paper } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";

const carouselItems: Item[] = [
  {
    name: "Dashboard1",
    caption: "Dashboard1",
    image: "mng",
  },
  {
    name: "Dashboard2",
    caption: "Dashboard2",
    image: "charts",
  },
  {
    name: "Dashboard3",
    caption: "Dashboard3",
    image: "shpcnt",
  },
  {
    name: "Dashboard4",
    caption: "Dashboard4",
    image: "pld",
  },
  {
    name: "Dashboard5",
    caption: "Dashboard5",
    image: "midas",
  },
];

const Gallery = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }} id="gallery">
      <Title>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <PhotoLibraryIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.gallery")}
        </Box>
      </Title>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CarouselCV items={carouselItems} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Gallery;
