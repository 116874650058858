import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CallIcon from "@mui/icons-material/Call";
import { Box, Container, Divider, Grid, List, ListItem } from "@mui/material";
import careerDetails from "@pages/Career/CareerDetails.json";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const getTranslationKey = (
  company: string,
  domain: string,
  suffix: string
): string => {
  return `experience.${company}.details.${domain}.${suffix}`;
};

interface DomainAndTechnologyDetails {
  domain: string;
  technology: string;
}

const toItemsList = (
  prefix: string,
  item: DomainAndTechnologyDetails,
  t: TFunction<"translation", undefined>
) => {
  return (
    <ListItem sx={{ display: "list-item" }}>
      {t(getTranslationKey(prefix, item.domain, item.technology))}
    </ListItem>
  );
};

const GetCareerListItemsByCompany = (
  company: string,
  useDivider: boolean
): JSX.Element => {
  const { t } = useTranslation();

  const companyDetails = careerDetails.experiences.find(
    (careerDetail) => careerDetail.prefix === company
  );
  if (!companyDetails) {
    return <React.Fragment></React.Fragment>;
  }

  const { prefix, backend, frontend, devOps, organization } = companyDetails;

  const frontendFmt = frontend.map((technology) =>
    toItemsList(prefix, { domain: "frontend", technology }, t)
  );

  const backendFmt = backend.map((technology) =>
    toItemsList(prefix, { domain: "backend", technology }, t)
  );
  const devOpsFmt = devOps.map((technology) =>
    toItemsList(prefix, { domain: "devOps", technology }, t)
  );

  const organizationFmt = organization.map((technology) =>
    toItemsList(prefix, { domain: "organization", technology }, t)
  );

  const divider = useDivider ? (
    <Divider sx={{ mt: 2 }} />
  ) : (
    <React.Fragment></React.Fragment>
  );

  return (
    <React.Fragment>
      <List
        sx={{ listStyleType: "disc", paddingLeft: "125px" }}
        id={`pdf-experience-${company}-frontend`}
      >
        {frontendFmt}
      </List>
      <List
        sx={{ listStyleType: "disc", paddingLeft: "125px" }}
        id={`pdf-experience-${company}-backend`}
      >
        {backendFmt}
      </List>
      <List
        sx={{ listStyleType: "disc", paddingLeft: "125px" }}
        id={`pdf-experience-${company}-devOps`}
      >
        {devOpsFmt}
      </List>
      <List
        sx={{ listStyleType: "disc", paddingLeft: "125px" }}
        id={`pdf-experience-${company}-organization`}
      >
        {organizationFmt}
      </List>
      <Box
        id={`pdf-experience-${company}-divider`}
        sx={{ paddingLeft: "110px", marginTop: "2px" }}
      >
        {divider}
      </Box>
    </React.Fragment>
  );
};

const PDF = () => {
  const { t } = useTranslation();

  const background = ["master", "degree"].map((study) => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h4>{t(`background.${study}.title`)}</h4>
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            {t(`background.${study}.dates`)}
          </Grid>
          <Grid item xs={4} sx={{ mt: 3 }}>
            {t(`background.${study}.location`)}
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const languages = ["en", "fr", "es"].map((language) => {
    return (
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <h4>{t(`languages.${language}.name`)}</h4>
          </Grid>
          <Grid item xs={6} sx={{ mt: 3 }}>
            {t(`languages.${language}.level`)}
          </Grid>
        </Grid>
      </Grid>
    );
  });

  const companies = ["yuzer", "dive", "tecsisa"];
  const experience = companies.map((company, index) => {
    const useDivider = index === companies.length - 1;
    return (
      <Grid item xs={12}>
        <Box
          id={`pdf-experience-${company}`}
          sx={{ paddingLeft: "110px", paddingTop: "2px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <h4>{t(`experience.${company}.name`)}</h4>
            </Grid>
            <Grid item xs={4} sx={{ mt: 3 }}>
              {t(`experience.${company}.dates`)}
            </Grid>
            <Grid item xs={4} sx={{ mt: 1 }}>
              <h5>{t(`experience.${company}.role`)}</h5>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ paddingTop: "2px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {GetCareerListItemsByCompany(company, useDivider)}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    );
  });

  return (
    <Box id="pdf-content" sx={{ display: "none", width: "1080px" }}>
      <h1 style={{ textAlign: "center", paddingTop: "80px" }} id="pdf-name">
        Alex Rene Cañar Gutierrez
      </h1>
      <Container maxWidth="lg">
        <Box id="pdf-contact" sx={{ paddingLeft: "110px", paddingTop: "2px" }}>
          <Divider sx={{ mt: 2 }} />
          <h2>{t("navigation.contact")}</h2>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end", mb: 0.5 }}>
                <AlternateEmailIcon sx={{ color: "action.active", mr: 1 }} />
                alcagut@gmail.com
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "flex-end", mb: 0.5 }}>
                <CallIcon sx={{ color: "action.active", mr: 1 }} />
                +34 647 953 697
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box
          id="pdf-background"
          sx={{ paddingLeft: "110px", paddingTop: "2px" }}
        >
          <h2> {t("navigation.education")}</h2>
          <Grid container spacing={2}>
            {background}
          </Grid>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box
          id="pdf-languages"
          sx={{ paddingLeft: "110px", paddingTop: "2px" }}
        >
          <h2> {t("navigation.languages")}</h2>
          <Grid container spacing={2}>
            {languages}
          </Grid>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box
          id="pdf-experience"
          sx={{ paddingLeft: "110px", paddingTop: "2px" }}
        >
          <h2> {t("navigation.experience")}</h2>
        </Box>
        <Grid container spacing={2}>
          {experience}
        </Grid>
      </Container>
    </Box>
  );
};

export default PDF;
