import ContactsIcon from "@mui/icons-material/Contacts";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import SchoolIcon from "@mui/icons-material/School";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

export interface NavBarItem {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  text: string;
}

export const navBarItems: NavBarItem[] = [
  { icon: ContactsIcon, text: "navigation.contact" },
  { icon: SchoolIcon, text: "navigation.background" },
  { icon: WorkHistoryIcon, text: "navigation.experience" },
  { icon: PhotoLibraryIcon, text: "navigation.gallery" },
];
