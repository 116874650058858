import { changeLanguage } from "@components/i18n/i18n";
import {
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  styled,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import MuiDrawer from "@mui/material/Drawer";
import { ES, FR, US } from "country-flag-icons/react/3x2";
import { navBarItems } from "./NavBarItemList";

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const languages: { text: string; flag: any }[] = [
  { text: "EN", flag: US },
  { text: "ES", flag: ES },
  { text: "FR", flag: FR },
];

const NavBar = (): React.ReactElement<any> => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (): void => {
    setOpen(!open);
  };
  const [openLanguages, setOpenLanguages] = React.useState(false);

  const onLanguagesClick = () => {
    setOpenLanguages(!openLanguages);
  };

  const [selectedLng, setSelectedLng] = React.useState("EN");
  const handleChangeLan = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    lng: string
  ) => {
    const lngFmt = lng.toLocaleLowerCase();
    setSelectedLng(lng);
    changeLanguage(lngFmt);
    setOpenLanguages(!openLanguages);
  };

  const items = navBarItems.map((navBarItem) => (
    <ListItemButton href={`#${navBarItem.text.split(".")[1]}`}>
      <Tooltip
        title={t(navBarItem.text)}
        placement="right"
        disableHoverListener={open}
      >
        <ListItemIcon>
          <navBarItem.icon color="primary" />
        </ListItemIcon>
      </Tooltip>

      <ListItemText primary={t(navBarItem.text)} />
    </ListItemButton>
  ));

  const languagesItems = languages.map((lng) => {
    const lngKey = `languages.${lng.text.toLocaleLowerCase()}.name`;

    const lngText = open ? (
      <ListItemText primary={t(lngKey)} sx={{ marginLeft: 2 }} />
    ) : (
      <React.Fragment></React.Fragment>
    );
    return (
      <ListItemButton
        selected={selectedLng === lng.text}
        onClick={(event) => handleChangeLan(event, lng.text)}
        sx={{ svg: { maxWidth: "59px", maxHeight: "39px" } }}
      >
        <lng.flag title={lng.text} />
        {lngText}
      </ListItemButton>
    );
  });

  const expandLanguages = openLanguages ? <ExpandLess /> : <ExpandMore />;

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          // backgroundImage: 'linear-gradient(#CEFDF5, #EAFEFA)',
          paddingRight: "0px!important",
          paddingLeft: "0px!important",
        }}
        id="toolbar"
      >
        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                ...(!open && { display: "none" }),
              }}
            >
              <IconButton edge="start" onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                ...(open && { display: "none" }),
              }}
            >
              <IconButton edge="start" onClick={toggleDrawer}>
                <MenuIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
      <Divider />
      <List component="nav">
        {items}
        <ListItemButton onClick={onLanguagesClick}>
          <Tooltip
            title={t("navigation.languages")}
            placement="right"
            disableHoverListener={open}
          >
            <ListItemIcon>
              <LanguageIcon color="primary" />
              {open ? <React.Fragment></React.Fragment> : expandLanguages}
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t("navigation.languages")} />
          {open ? expandLanguages : <React.Fragment></React.Fragment>}
        </ListItemButton>
        <Collapse in={openLanguages} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {languagesItems}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
};

export default NavBar;
