import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TocIcon from "@mui/icons-material/Toc";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface CareerStep {
  company: string;
  frontend: string[];
  backend: string[];
  devOps: string[];
  organization: string[];
  id: string;
}

const CareerStepList: React.FC<CareerStep> = ({
  company,
  backend,
  frontend,
  devOps,
  organization,
  id,
}) => {
  const { t } = useTranslation();

  const [openBack, setOpenBack] = React.useState(true);
  const [openFront, setOpenFront] = React.useState(true);
  const [openDevOps, setOpenDevOps] = React.useState(true);
  const [openOrganization, setOpenOrganization] = React.useState(true);

  const handleClickBack = () => {
    setOpenBack(!openBack);
  };
  const handleClickFront = () => {
    setOpenFront(!openFront);
  };
  const handleClickDev = () => {
    setOpenDevOps(!openDevOps);
  };
  const handleClickOrg = () => {
    setOpenOrganization(!openOrganization);
  };

  const getTranslationKey = (
    company: string,
    domain: string,
    suffix: string
  ): string => {
    return `experience.${company}.details.${domain}.${suffix}`;
  };

  const backendTechList = backend.map((technology, index) => (
    <ListItemButton
      sx={{ pl: 4, cursor: "default" }}
      key={`Backend-${id}-${index}`}
    >
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText
        primary={t(getTranslationKey(company, "backend", technology))}
      />
    </ListItemButton>
  ));

  const backendList = (
    <React.Fragment>
      <ListItemButton onClick={handleClickBack} key={`Backend${id}`}>
        <ListItemIcon>
          <ViewListIcon />
        </ListItemIcon>
        <ListItemText primary="Backend" />
        {openBack ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openBack} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {backendTechList}
        </List>
      </Collapse>
    </React.Fragment>
  );

  const frontTechList = frontend.map((technology, index) => (
    <ListItemButton
      sx={{ pl: 4, cursor: "default" }}
      key={`FrontEnd-${id}-${index}`}
    >
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText
        primary={t(getTranslationKey(company, "frontend", technology))}
      />
    </ListItemButton>
  ));

  const frontList =
    frontend.length === 0 ? (
      <React.Fragment />
    ) : (
      <React.Fragment>
        <ListItemButton onClick={handleClickFront} key={`FrontEnd${id}`}>
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="FrontEnd" />
          {openFront ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openFront} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {frontTechList}
          </List>
        </Collapse>
      </React.Fragment>
    );

  const devOpsTechList = devOps.map((technology, index) => (
    <ListItemButton
      sx={{ pl: 4, cursor: "default" }}
      key={`DevOps-${id}-${index}`}
    >
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText
        primary={t(getTranslationKey(company, "devOps", technology))}
      />
    </ListItemButton>
  ));

  const devOpsList =
    devOps.length === 0 ? (
      <React.Fragment />
    ) : (
      <React.Fragment>
        <ListItemButton onClick={handleClickDev} key={`DevOps${id}`}>
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="DevOps" />
          {openDevOps ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openDevOps} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {devOpsTechList}
          </List>
        </Collapse>
      </React.Fragment>
    );

  const organizationTechList = organization.map((technology, index) => (
    <ListItemButton
      sx={{ pl: 4, cursor: "default" }}
      key={`Organization-${id}-${index}`}
    >
      <ListItemIcon>
        <TocIcon />
      </ListItemIcon>
      <ListItemText
        primary={t(getTranslationKey(company, "organization", technology))}
      />
    </ListItemButton>
  ));

  const organizationList = (
    <React.Fragment>
      <ListItemButton onClick={handleClickOrg} key={`Organization${id}`}>
        <ListItemIcon>
          <ViewListIcon />
        </ListItemIcon>
        <ListItemText primary="Organization" />
        {openBack ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOrganization} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {organizationTechList}
        </List>
      </Collapse>
    </React.Fragment>
  );

  return (
    <List
      sx={{ bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      key={id}
    >
      {frontList}
      {backendList}
      {devOpsList}
      {organizationList}
    </List>
  );
};

export default CareerStepList;
