import profile from "@assets/images/profile.jpg";
import Title from "@components/Titles";
import i18n from "@components/i18n/i18n";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CallIcon from "@mui/icons-material/Call";
import ContactsIcon from "@mui/icons-material/Contacts";
import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import * as React from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const pdfSections = [
  "pdf-name",
  "pdf-contact",
  "pdf-background",
  "pdf-languages",
  "pdf-experience",
  "pdf-experience-yuzer",
  "pdf-experience-yuzer-frontend",
  "pdf-experience-yuzer-backend",
  "pdf-experience-yuzer-devOps",
  "pdf-experience-yuzer-organization",
  "pdf-experience-dive",
  "pdf-experience-dive-frontend",
  "pdf-experience-dive-backend",
  "pdf-experience-dive-devOps",
  "pdf-experience-dive-organization",
  "pdf-experience-tecsisa",
  "pdf-experience-tecsisa-frontend",
  "pdf-experience-tecsisa-backend",
  "pdf-experience-tecsisa-devOps",
  "pdf-experience-tecsisa-organization",
  "pdf-experience-tecsisa-divider",
];

const Contact = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  const handleDownloadPDF = async () => {
    const pdf = new jsPdf("p", "mm", "a4", true); // A4 size page of PDF
    const currentLang = i18n.language.toUpperCase();
    const pdfTitle = `Cañar Gutierrez, Alex Rene ${currentLang}.pdf`;

    const sectionsHtml = pdfSections.map((section) => {
      const sectionSelected = document.getElementById(section);
      if (!sectionSelected) {
        throw new Error(`Could not find element with id ${section} in DOM`);
      }
      return html2canvas(sectionSelected, {
        onclone: (clonedDoc) => {
          const mainDiv = clonedDoc.getElementById("pdf-content");
          if (mainDiv) {
            mainDiv.style.display = "block";
          }
        },
      });
    });

    const imgWidth = 208;
    const pageHeight = 295;

    Promise.all(sectionsHtml).then((canvasElements) => {
      let currentPageHeight = 0;
      canvasElements.forEach((canvas) => {
        const contentDataURL = canvas.toDataURL("image/png");
        // Few necessary setting options
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        if (currentPageHeight + imgHeight > pageHeight) {
          pdf.addPage();
          currentPageHeight = 15; // expected only 1 page
        }
        pdf.addImage(
          contentDataURL,
          "PNG",
          0,
          currentPageHeight,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        currentPageHeight += imgHeight;
      });
      pdf.save(pdfTitle);
    });
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
      id="contact"
    >
      <Title>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <ContactsIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.contact")}
        </Box>
      </Title>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Card>
              <CardMedia
                component="img"
                height="350"
                image={profile}
                alt="profile photo"
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {t("contact.profile")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "flex-end", mb: 0.5 }}>
                  <AlternateEmailIcon sx={{ color: "action.active", mr: 1 }} />
                  alcagut@gmail.com
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "flex-end", mb: 0.5 }}>
                  <CallIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  <PhoneInput
                    country="Spain"
                    value="+34647953697"
                    disableDropdown
                    inputProps={{ readOnly: true }}
                    inputStyle={{ width: "auto" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "flex-end", my: 0.5 }}>
                  <Tooltip
                    title={t("labels.pdfTitleDetails")}
                    placement="bottom"
                  >
                    <IconButton
                      edge="start"
                      sx={{
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent",
                          cursor: "default",
                        },
                      }}
                    >
                      <InfoIcon sx={{ mr: 1 }} color="info" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t("actions.downloadAsPdf")}
                    placement="right-start"
                  >
                    <IconButton edge="start" onClick={handleDownloadPDF}>
                      <PictureAsPdfIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default Contact;
