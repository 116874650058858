import charts from "@assets/images/charts.jpg";
import midas from "@assets/images/midas.jpg";
import mng from "@assets/images/mng.jpg";
import pld from "@assets/images/pld.jpg";
import shpcnt from "@assets/images/shpcnt.jpg";

const IMG_NAME_SRC_MAP: Record<string, string> = {
  mng: mng,
  shpcnt: shpcnt,
  pld: pld,
  midas: midas,
  charts: charts,
};
export const getImageLocalPath = (imgKey: string): string => {
  return IMG_NAME_SRC_MAP[imgKey] ?? charts;
};
