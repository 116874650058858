import { Card, CardMedia, Grid } from "@mui/material";
import * as React from "react";
import Carousel from "react-material-ui-carousel";
import "react-phone-input-2/lib/style.css";
import { getImageLocalPath } from "./ImagesBD";
import "./styles.css";

export interface SettingsT {
  autoPlay: boolean;
  animation: "fade" | "slide";
  indicators: boolean;
  duration: number;
  navButtonsAlwaysVisible: boolean;
  navButtonsAlwaysInvisible: boolean;
  fullHeightHover: boolean;
  cycleNavigation: boolean;
  swipe: boolean;
  [key: string]: any;
}

const defaultSettingsT: SettingsT = {
  autoPlay: false,
  animation: "slide",
  indicators: true,
  duration: 500,
  navButtonsAlwaysVisible: true,
  navButtonsAlwaysInvisible: false,
  cycleNavigation: false,
  fullHeightHover: false,
  swipe: true,
};

export type Item = {
  name: string;
  caption: string;
  image: string;
};

interface BannerProps {
  item: Item;
}

interface Props {
  items: Item[];
  customSettings?: SettingsT;
}

const Banner = (props: BannerProps) => {
  return (
    <Card raised sx={{ height: "100%" }}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid item xs={12} key={props.item.name}>
          <CardMedia
            image={getImageLocalPath(props.item.image)}
            title={props.item.name}
            component="img"
          ></CardMedia>
        </Grid>
      </Grid>
    </Card>
  );
};

const CarouselCV = (props: Props): React.ReactElement<any> => {
  const { items, customSettings } = props;
  const settings: SettingsT = customSettings ?? defaultSettingsT;

  return (
    <Carousel
      sx={{ mt: 2 }}
      {...settings}
      // next={(now: any, previous:any) => console.log(`Next User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
      // prev={(now, previous) => console.log(`Prev User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
      // onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}

      // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
      // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
      // indicatorContainerProps={{style: {margin: "20px"}}}
      // NextIcon='next'
    >
      {items.map((item, index) => {
        return <Banner item={item} key={index} />;
      })}
    </Carousel>
  );
};

export default CarouselCV;
