import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { Box, Card, CardContent, Container, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

import Title from "@components/Titles";
import { useTranslation } from "react-i18next";
import data from "./CareerDetails.json";
import CareerStepList, { CareerStep } from "./CareerStepList";

interface Props {
  company: string;
  role: string;
  steps: CareerStep;
}

const CareerItem: React.FC<Props> = ({ company, role, steps }) => {
  return (
    <Card>
      <CardContent>
        <Box
          sx={{ display: "flex", alignItems: "flex-end" }}
          id={`career-${steps.company}`}
        >
          <BusinessIcon sx={{ color: "action.active", mr: 1, my: 1.5 }} />
          <Typography gutterBottom variant="h5" component="div">
            {company}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <AccountCircleIcon sx={{ color: "action.active", mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {role}
          </Typography>
        </Box>
        <CareerStepList
          company={steps.company}
          frontend={steps.frontend}
          backend={steps.backend}
          devOps={steps.devOps}
          organization={steps.organization}
          id={steps.id}
        />
      </CardContent>
    </Card>
  );
};

const Career = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  const careerList = data.experiences.map((experience) => {
    const { company, prefix, role, backend, frontend, devOps, organization } =
      experience;
    const steps: CareerStep = {
      backend,
      organization,
      frontend,
      devOps,
      id: company,
      company: prefix,
    };

    return (
      <Grid item xs={12}>
        <CareerItem company={company} role={role} steps={steps} />
      </Grid>
    );
  });
  return (
    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      <Title>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <WorkHistoryIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.career")}
        </Box>
      </Title>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {careerList}
        </Grid>
      </Container>
    </Paper>
  );
};

export default Career;
