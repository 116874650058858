import { Typography } from "@mui/material";
import * as React from "react";
import "react-phone-input-2/lib/style.css";

const Copyright = (props: any): React.ReactElement<any> => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © arcg"}
    </Typography>
  );
};

export default Copyright;
