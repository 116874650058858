import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as React from "react";

import Copyright from "@components/Copyright";
import NavBar from "@components/NavBar";
import PDF from "@components/PDF";
import BackGround from "@pages/BackGround";
import Career from "@pages/Career";
import Contact from "@pages/Contact";
import Experience from "@pages/Experience";
import Gallery from "@pages/Gallery";

// TODO remove, this demo shouldn't need to reset the theme.
export const defaultTheme = createTheme({
  palette: { primary: { main: "#3AB5E4", contrastText: "#FFFFFF" } },
});

const App = (): React.ReactElement<any> => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavBar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <PDF />
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Contact />
              </Grid>
              <Grid item xs={12}>
                <BackGround />
              </Grid>
              <Grid item xs={12}>
                <Experience />
              </Grid>
              <Grid item xs={12}>
                <Career />
              </Grid>
              <Grid item xs={12}>
                <Gallery />
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default App;
