import { defaultTheme } from "@components/App";
import { SubTitle } from "@components/Titles";
import FlagIcon from "@mui/icons-material/Flag";
import GradeIcon from "@mui/icons-material/Grade";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  level: string;
}

const LaguageItem: React.FC<Props> = ({ name, level }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <FlagIcon sx={{ marginRight: defaultTheme.spacing(1) }} />
          {t(name)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <GradeIcon sx={{ marginRight: defaultTheme.spacing(1) }} />
          {t(level)}
        </Typography>
      </CardContent>
    </Card>
  );
};
const Languages = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  const languages = ["en", "fr", "es"].map((language) => (
    <Grid item xs={12} md={4} lg={4}>
      <LaguageItem
        name={`languages.${language}.name`}
        level={`languages.${language}.level`}
      />
    </Grid>
  ));

  return (
    <React.Fragment>
      <SubTitle>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <LanguageIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.languages")}
        </Box>
      </SubTitle>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {languages}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Languages;
