import Education from "@components/Education";
import Languages from "@components/Languages";
import Title from "@components/Titles";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Container, Grid, Paper } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

const BackGround = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
      id="background"
    >
      <Title>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <SchoolIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.background")}
        </Box>
      </Title>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Education />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Languages />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

export default BackGround;
