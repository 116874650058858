import { SubTitle } from "@components/Titles";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Card, CardContent, Container, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  location: string;
  dateRange: string;
}

const EducationItem: React.FC<Props> = ({ title, location, dateRange }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <SchoolIcon sx={{ color: "action.active", mr: 1, my: 1.5 }} />
          <Typography gutterBottom variant="h5" component="div">
            {t(title)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <LocationOnIcon sx={{ color: "action.active", mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {t(location)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <DateRangeIcon sx={{ color: "action.active", mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {t(dateRange)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const Education = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  const educationDetails = ["master", "degree"].map((study) => (
    <Grid item xs={12} md={6} lg={6}>
      <EducationItem
        title={`background.${study}.title`}
        location={`background.${study}.location`}
        dateRange={`background.${study}.dates`}
      />
    </Grid>
  ));

  return (
    <React.Fragment>
      <SubTitle>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <HistoryEduIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.education")}
        </Box>
      </SubTitle>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {educationDetails}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Education;
