import Title from "@components/Titles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import DateRangeIcon from "@mui/icons-material/DateRange";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  company: string;
  role: string;
  dateRange: string;
}

const ExperienceItem: React.FC<Props> = ({ company, role, dateRange }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <BusinessIcon sx={{ color: "action.active", mr: 1, my: 1.5 }} />
          <Typography gutterBottom variant="h5" component="div">
            {t(company)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <AccountCircleIcon sx={{ color: "action.active", mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {t(role)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <DateRangeIcon sx={{ color: "action.active", mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {t(dateRange)}
          </Typography>
        </Box>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          color="primary"
          href={`#career-${company.split(".")[1]}`}
        >
          More details
        </Button>
      </CardActions>
    </Card>
  );
};
const Experience = (): React.ReactElement<any> => {
  const { t } = useTranslation();

  const experiences = ["yuzer", "dive", "tecsisa"].map((company) => (
    <Grid item xs={12} md={4} lg={4}>
      <ExperienceItem
        company={`experience.${company}.name`}
        role={`experience.${company}.role`}
        dateRange={`experience.${company}.dates`}
      />
    </Grid>
  ));

  return (
    <Paper
      sx={{ p: 2, display: "flex", flexDirection: "column" }}
      id="experience"
    >
      <Title>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <WorkHistoryIcon sx={{ mr: 1, my: 0.5 }} />
          {t("navigation.experience")}
        </Box>
      </Title>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {experiences}
        </Grid>
      </Container>
    </Paper>
  );
};

export default Experience;
